<template>
  <div>
    <KTCodePreview v-bind:title="billDetailTitle">
      <template v-slot:preview>
        <b-form ref="form" lazy-validation class="row">
          <b-container class="bv-example-row">
            <b-row>
              <b-col class="col-md-3">
                <b-form-group>
                  <label>Cửa hàng:</label>
                  <vue-autosuggest
                    :suggestions="[]"
                    :limit="10"
                    :input-props="{
                      class: 'autosuggest__input',
                      placeholder: 'Tên cửa hàng',
                      disabled: true,
                    }"
                    :should-render-suggestions="
                      (size, loading) => size >= 0 && !loading
                    "
                    v-model="mainModel.storeName"
                  >
                    <template slot-scope="{ suggestion }">{{
                      suggestion.item.name
                    }}</template>
                  </vue-autosuggest>
                </b-form-group>

                <b-form-group>
                  <label>Phí trả hàng:</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="mainModel.returnFee"
                    placeholder="Phí trả hàng"
                    v-mask="mask"
                    :disabled="!editable"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <label>Nhân viên thu ngân:</label>
                  <vue-autosuggest
                    :suggestions="filteredOptionsEmployee"
                    @selected="onSelectedCashier"
                    :limit="100"
                    @input="onInputChangeCashier"
                    :input-props="inputPropCashier"
                    v-model="mainModel.cashierName"
                  >
                    <template slot-scope="{ suggestion }">{{
                      suggestion.item.fullName
                    }}</template>
                  </vue-autosuggest>
                </b-form-group>

                <b-form-group>
                  <label>Ghi chú bán hàng:</label>
                  <b-form-textarea
                    size="sm"
                    class="input-style"
                    v-model="mainModel.customerNote"
                    :placeholder="'Thêm ghi chú'"
                    :rows="6"
                    :max-rows="6"
                    :disabled="!editable"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col class="col-md-3">
                <b-form-group>
                  <label>Họ và tên:</label>
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="mainModel.customerName"
                    placeholder="Họ tên khách hàng"
                    :disabled="true"
                  ></b-form-input>
                </b-form-group>
                <b-form-group>
                  <label>Điện thoại:</label>
                  <vue-autosuggest
                    :suggestions="filteredOptionsCustomer"
                    :limit="100"
                    :input-props="{
                      class: 'autosuggest__input',
                      placeholder: 'SĐT Khách hàng',
                      disabled: true,
                    }"
                    v-model="mainModel.customerPhone"
                  >
                    <template slot-scope="{ suggestion }">{{
                      suggestion.item.phoneNo
                    }}</template>
                  </vue-autosuggest>
                </b-form-group>

                <b-form-group>
                  <label>Địa chỉ:</label>
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="mainModel.customerAddress"
                    placeholder="Địa chỉ khách hàng"
                    :disabled="true"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <label>Ghi chú nội bộ:</label>
                  <b-form-textarea
                    size="sm"
                    class="input-style"
                    v-model="mainModel.saleNote"
                    :placeholder="'Thêm ghi chú'"
                    :rows="6"
                    :max-rows="6"
                    :disabled="!editable"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col class="col-md-3">
                <b-form-group>
                  <label>Trả hàng từ hóa đơn:</label>
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="mainModel.relatedBillNumber"
                    placeholder="Mã hóa đơn"
                    :disabled="true"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <label>Đã sử dụng điểm tích lũy:</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="mainModel.subPoint"
                    placeholder="Số điểm đã tiêu"
                    :disabled="true"
                  ></b-form-input>
                </b-form-group>
                <b-form-group>
                  <label>Được cộng điểm tích lũy:</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="mainModel.addPoint"
                    placeholder="Số điểm được cộng"
                    :disabled="true"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="col-md-3">
                <b-form-group>
                  <label>Tiền mặt trả khách:</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="mainModel.paymentInfo.cashAmount"
                    placeholder="Nhập tiền mặt trả khách"
                    v-mask="mask"
                    :disabled="!editable"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <label>Tài khoản trả tiền:</label>
                  <vue-autosuggest
                    v-model="mainModel.paymentInfo.cashAccountName"
                    :suggestions="filteredCashAccOptions"
                    @selected="onCashAccSelected"
                    :limit="100"
                    @input="onInputCashAccChange"
                    :input-props="inputPropCash"
                  >
                    <template slot-scope="{ suggestion }">{{
                      suggestion.item.name
                    }}</template>
                  </vue-autosuggest>
                </b-form-group>
                <b-button
                  variant="secondary"
                  size="sm"
                  block
                  class="font-weight-bolder mb-2"
                  @click="showApprovalModal(1)"
                >
                  <i class="fa fa-plus-circle"></i>
                  Tạo duyệt chi</b-button
                >
                <ApprovalModal ref="popup-approval" />
                <b-form-group>
                  <label>Tiền chuyển khoản trả khách:</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="mainModel.paymentInfo.transferAmount"
                    placeholder="Nhập tiền chuyển khoản trả khách"
                    v-mask="mask"
                    :disabled="!editable"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <label>Tài khoản chuyển khoản:</label>
                  <vue-autosuggest
                    v-model="mainModel.paymentInfo.transferAccountName"
                    :suggestions="filteredAccOptions"
                    @selected="onTransferAccountantSelected"
                    :limit="100"
                    @input="onInputTransferAccountantChange"
                    :input-props="inputPropTransfer"
                  >
                    <template slot-scope="{ suggestion }">{{
                      suggestion.item.name
                    }}</template>
                  </vue-autosuggest>
                </b-form-group>
                <b-button
                  variant="secondary"
                  size="sm"
                  block
                  class="font-weight-bolder mb-2"
                  @click="showApprovalModal(2)"
                >
                  <i class="fa fa-plus-circle"></i>
                  Tạo duyệt chi</b-button
                >
                <ApprovalModal ref="popup-approval" />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <table
                  class="table table-bordered table-vertical-center table-hover"
                >
                  <thead>
                    <tr>
                      <th class="headerTable" style="textalign: center">STT</th>
                      <th class="headerTable">Sản phẩm</th>
                      <th class="headerTable">Số lượng</th>
                      <th class="headerTable">Giá</th>
                      <th class="headerTable">CK phân bổ</th>
                      <th class="headerTable">Thành tiền</th>
                      <th class="headerTable">Chiết khấu</th>
                      <th v-if="false"></th>
                    </tr>
                  </thead>

                  <tbody v-for="(item, index) in listBillItem" :key="item.id">
                    <ReturnBillItem
                      v-bind:productItem="item"
                      v-bind:index="index"
                      v-bind:editable="false"
                      v-on:deleteItem="deleteItemInArray"
                      v-on:update="updateItem"
                      v-on:checkValidImei="checkValidImei"
                    />
                  </tbody>
                  <tfoot>
                    <tr>
                      <th style="text-align: right" colspan="4">
                        Tổng phải trả:
                      </th>
                      <td style="text-align: right; padding: 0px 20px">
                        {{ convertPrice(totalPrice) }}
                      </td>
                      <td></td>
                      <td v-if="false"></td>
                    </tr>
                  </tfoot>
                </table>
              </b-col>
            </b-row>
          </b-container>
          <b-container
            class="bv-example-row"
            v-if="checkPermission('TRANSACTION_VIEW')"
          >
            <hr
              class="hr-text"
              data-content="Bút Toán"
              style="font-weight: 600"
            />
            <b-row>
              <b-col md="12" lg="12" sm="12">
                <BillTransactions :billNumber="$route.query.id" />
              </b-col>
            </b-row>
          </b-container>
        </b-form>
      </template>

      <template v-slot:foot>
        <b-container class="bv-example-row">
          <b-row>
            <b-button
              tabindex="15"
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="onSubmit"
              >Lưu</b-button
            >
            <router-link to="/orders" tag="button">
              <b-button
                tabindex="16"
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
          </b-row>
        </b-container>
      </template>
    </KTCodePreview>
  </div>
</template>
<style scoped>
.form-group label {
  font-weight: 500;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import ApiService from '@/core/services/api.service';
import {
  currencyMask,
  removeAccents,
  unMaskPrice,
  xoa_dau,
} from '@/utils/common';
import { validationMixin } from 'vuelidate';
import ReturnBillItem from '../../components/ReturnBillItem.vue';
import BillTransactions from '../../components/BillTransactions.vue';
import localData from '@/utils/saveDataToLocal';
import {
  AMOUNT_TYPE,
  PAYMENT_TYPE,
  BILL_ITEM_TYPE,
  APPROVAL_PAYMENT_TYPE,
  APPROVAL_TYPE_ENUM,
} from '@/utils/enum';
import _ from 'lodash';
import ApprovalModal from '@/view/components/approval/ApprovalModal.vue';

export default {
  mixins: [validationMixin],
  data() {
    return {
      cashAccOptions: [
        {
          data: [],
        },
      ],
      filteredCashAccOptions: [],
      accountantOptions: [
        {
          data: [],
        },
      ],
      filteredAccOptions: [],

      filteredOptionsEmployee: [],
      optionsEmployee: [
        {
          data: [],
        },
      ],
      mask: currencyMask,
      listBillItem: [],
      proSTT: 0,
      billDetailTitle: 'Phiếu trả hàng',
      editable: true,
      inputPropCashier: {
        class: 'autosuggest__input',
        placeholder: 'Nhân viên thu ngân',
        disabled: false,
      },
      filteredOptionsCustomer: [],
      mainModel: {
        id: null,
        billNumber: null,
        storeId: null,
        storeName: '',
        cashierId: null,
        cashierName: '',
        customerPhone: '',
        customerName: '',
        customerAddress: '',
        customerNote: '',
        saleNote: '',
        returnFee: 0,
        addPoint: 0,
        subPoint: 0,
        relatedBillId: null,
        relatedBillNumber: 0,
        paymentInfo: {
          cashId: null,
          cashAmount: 0,
          cashAccountName: '',
          cashAccountCode: '',
          cashAccountId: null,
          transferId: null,
          transferAmount: 0,
          transferAccountName: '',
          transferAccountCode: '',
          transferAccountId: null,
        },
      },
      inputPropCash: {
        class: 'autosuggest__input',
        placeholder: 'Tài khoản tiền mặt',
        disabled: false,
      },
      inputPropTransfer: {
        class: 'autosuggest__input',
        placeholder: 'Tài khoản chuyển khoản',
        disabled: false,
      },
    };
  },
  methods: {
    fetchCashAcc: async function() {
      const params = {
        storeId: this.mainModel.storeId,
        type: 1,
      };
      await ApiService.query('accountants/getByStore', { params }).then(
        (response) => {
          this.cashAccOptions[0].data = [];
          response.data.data.forEach((item) => {
            let acc = {
              id: item.id,
              name: item.name,
              code: item.code,
            };
            this.cashAccOptions[0].data.push(acc);
          });
          this.filteredCashAccOptions = [{ data: this.cashAccOptions[0].data }];
        },
      );
    },
    onInputCashAccChange(text) {
      const filteredData = this.cashAccOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCashAccOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onCashAccSelected(option) {
      this.mainModel.paymentInfo.cashAccountName = option.item.name;
      this.mainModel.paymentInfo.cashAccountCode = option.item.code;
      this.mainModel.paymentInfo.cashAccountId = option.item.id;
    },
    onInputTransferAccountantChange(text) {
      this.mainModel.paymentInfo.transferAccountName = text;

      const filteredData = this.accountantOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredAccOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onTransferAccountantSelected(option) {
      this.mainModel.paymentInfo.transferAccountName = option.item.name;
      this.mainModel.paymentInfo.transferAccountCode = option.item.code;
      this.mainModel.paymentInfo.transferAccountId = option.item.id;
    },
    fetchAccountant: async function() {
      const params = {
        type: 2,
      };
      await ApiService.query('accountants/getByType', { params }).then(
        (response) => {
          this.accountantOptions[0].data = [];
          response.data.data.forEach((item) => {
            if (item.level >= 2) {
              const acc = {
                id: item.id,
                name: item.name,
                code: item.code,
              };

              this.accountantOptions[0].data.push(acc);
            }
          });
          this.filteredAccOptions = [{ data: this.accountantOptions[0].data }];
        },
      );
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    updateItem(item) {
      this.listBillItem.forEach((element, index) => {
        if (element.id === item.id) {
          this.listBillItem[index].name = item.name;
          this.listBillItem[index].imeiCode = item.imeiCode;
          this.listBillItem[index].proId = item.proId;
          this.listBillItem[index].price = parseInt(item.price);
          this.listBillItem[index].quantity = parseInt(item.quantity);
          this.listBillItem[index].totalPrice = item.totalPrice;
          this.listBillItem[index].discount = item.discount;
        }
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listBillItem.length; i++) {
        if (this.listBillItem[i].id === id) {
          this.listBillItem.splice(i, 1);
        }
      }
    },
    checkValidImei(value) {
      this.isValidImei = value;
    },
    convertPrice: function(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    onSubmit: async function() {
      const listPro = this.listBillItem.map((element) => {
        return {
          productId: element.productId,
          productPrice: unMaskPrice(element.price),
          quantity: element.quantity,
          discountAmount: unMaskPrice(element.discount),
          imeiCode: element.imeiCode,
        };
      });
      const data = {
        id: this.mainModel.id,
        storeId: this.mainModel.storeId,
        customerNote: this.mainModel.customerNote,
        customerName: this.mainModel.customerName,
        customerPhone: this.mainModel.customerPhone,
        address: this.mainModel.customerAddress,
        saleNote: this.mainModel.saleNote,
        cashAmount: unMaskPrice(this.mainModel.paymentInfo.cashAmount),
        cashId: this.mainModel.paymentInfo.cashId,
        selectedCashId: this.mainModel.paymentInfo.cashAccountId,
        cashAccountCode: this.mainModel.paymentInfo.cashAccountCode,
        cashAccountName: this.mainModel.paymentInfo.cashAccountName,
        transferId: this.mainModel.paymentInfo.transferId,
        transferAmount: unMaskPrice(this.mainModel.paymentInfo.transferAmount),
        transferAccountID: this.mainModel.paymentInfo.transferAccountId,
        transferAccountCode: this.mainModel.paymentInfo.transferAccountCode,
        transferAccountName: this.mainModel.paymentInfo.transferAccountName,
        listBillItem: listPro,
        returnFee: unMaskPrice(this.mainModel.returnFee),
        cashierId: this.mainModel.cashierId,
      };
      ApiService.put('bills/updateReturnBill', data).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.$router.push({
            path: '/returnBills',
          });
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    onSelectedCashier(option) {
      this.mainModel.cashierName = option.item.fullName;
      this.mainModel.cashierId = option.item.id;
    },
    onInputChangeCashier(text) {
      if (!text) {
        text = '';
      }
      this.mainModel.cashierName = text;
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item.fullName);
          return (
            item.fullName.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    getListEmployee() {
      ApiService.get('employees/getAll').then((data) => {
        const employees = data.data.data;
        employees.map((element) => {
          this.optionsEmployee[0].data.push(element);
        });
      });
    },
    checkPermission: function(condition) {
      return localData.checkPermission(condition);
    },
    getBillById() {
      this.onLoading = true;
      ApiService.get(`bills/${this.mainModel.billNumber}`).then((data) => {
        const dataResponse = data.data.data;
        const relateBill = dataResponse.relateBill;
        this.mainModel.relatedBillNumber = relateBill
          ? relateBill.billNumber
          : '';
        this.mainModel.addPoint = relateBill ? relateBill.addPoint : 0;
        this.mainModel.subPoint = relateBill ? relateBill.subtractPoint : 0;
        const totalSaleDiscountAmount = relateBill
          ? relateBill.discountValue
          : 0;
        const pointAmount = relateBill ? relateBill.pointAmount : 0;
        dataResponse.billItems.forEach((element) => {
          if (element.billItemType === BILL_ITEM_TYPE.PRODUCT) {
            this.proSTT++;
            const billItem = {
              id: element.id,
              productId: element.productId,
              name: element.productName,
              code: element.productCode,
              price: element.productPrice,
              type: element.productType,
              quantity: element.quantity,
              imeiCode: element.imeiCode,
              totalPrice:
                element.quantity * element.productPrice -
                element.discountAmount,
              discount: element.discountAmount,
              discountType: element.discountType,
              comboItems: element.listProductInCombo,
              billItemType: BILL_ITEM_TYPE.PRODUCT,
              checked: true,
              returnPrice: 0,
              accessoryGroupId: '',
              accessoryGroupCode: '',
              stt: this.proSTT,
              note: element.note,
              saleNote: element.saleNote,
              parentId: element.belongBillDetailId,
              listAccessoryPromotion: element.listAccessoryPromotion,
              quantityInStock: element.quantityInStock,
              allotmentDiscountAmount: 0,
              merchantId: element.merchantId || 1,
              customerDiscountType: element.customerDiscountType,
              customerDiscountAmount: element.customerDiscountAmount,
              customerDiscountSellingPrice:
                element.customerDiscountSellingPrice,
              customerDiscountMaxAmount: element.customerDiscountMaxAmount,
            };
            this.listBillItem.push(billItem);
          } else {
            let code = `Quà tặng kèm- ${element.accessoryGroupCode}`;
            if (element.billItemType === BILL_ITEM_TYPE.PRODUCT_PROMTION) {
              code = `SP bán kèm- ${element.accessoryGroupCode}`;
            }
            const billItem = {
              id: element.id,
              productId: element.productId,
              name: element.productName,
              code: code,
              price: element.productPrice,
              type: element.productType,
              quantity: element.quantity,
              imeiCode: element.imeiCode,
              totalPrice:
                element.quantity * element.productPrice -
                element.discountAmount,
              discount: element.discountAmount,
              discountType: AMOUNT_TYPE.MONEY,
              billItemType: element.billItemType,
              checked: element.isGiftTaken,
              returnPrice: element.repurchasePrice,
              accessoryGroupId: element.accessoryGroupId,
              accessoryGroupCode: element.accessoryGroupCode,
              parentId: element.belongBillDetailId,
              quantityInStock: element.quantityInStock,
              allotmentDiscountAmount: 0,
              merchantId: element.merchantId || 1,
            };
            this.listBillItem.push(billItem);
          }
        });
        this.listBillItem = this.splitTotalDiscountAmount(
          {
            totalAmountPoint: pointAmount,
            discountValue: totalSaleDiscountAmount,
            totalAmount: dataResponse.totalAmount,
            totalSaleAmount: relateBill.totalAmount,
            listBillItems: relateBill.listBillItems.length
              ? relateBill.listBillItems
              : [],
          },
          this.listBillItem,
        );
        // this.listBillItem = this.splitTotalDiscountAmount(
        //   {
        //     totalAmountPoint: pointAmount,
        //     discountValue: totalSaleDiscountAmount,
        //     totalAmount: dataResponse.totalAmount,
        //     totalSaleAmount: relateBill.totalAmount,
        //   },
        //   this.listBillItem,
        // );
        this.mainModel.customerNote = dataResponse.customerNote;
        this.mainModel.saleNote = dataResponse.saleNote;
        this.mainModel.cashierId = dataResponse.cashierId;
        this.mainModel.storeName = dataResponse.cashierName;
        this.mainModel.customerPhone = dataResponse.customerPhone;
        this.mainModel.customerAddress = dataResponse.customerAddress;
        this.mainModel.customerName = dataResponse.customerName;
        this.mainModel.storeName = dataResponse.storeName;
        this.mainModel.storeId = dataResponse.storeId;

        const payments = dataResponse.payments;
        const transfer = payments.filter(
          (item) => item.paymentType === PAYMENT_TYPE.TRANSFER,
        );
        const cash = payments.filter(
          (item) => item.paymentType === PAYMENT_TYPE.CASH,
        );
        if (cash.length) {
          this.mainModel.paymentInfo.cashId = cash[0].id;
          this.mainModel.paymentInfo.cashAccountId = cash[0].paymentRefId;
          this.mainModel.paymentInfo.cashAmount = cash[0].paymentAmount;
          this.mainModel.paymentInfo.cashAccountName = cash[0].paymentName;
          this.mainModel.paymentInfo.cashAccountCode = cash[0].paymentRefCode;
        }

        if (transfer.length) {
          this.mainModel.paymentInfo.transferId = transfer[0].id;
          this.mainModel.paymentInfo.transferAccountId =
            transfer[0].paymentRefId;
          this.mainModel.paymentInfo.transferAmount = transfer[0].paymentAmount;
          this.mainModel.paymentInfo.transferAccountName =
            transfer[0].paymentName;
          this.mainModel.paymentInfo.transferAccountCode =
            transfer[0].paymentRefCode;
        }

        this.mainModel.id = dataResponse.id;
        this.onLoading = false;
        this.mainModel.returnFee = dataResponse.returnFee;

        this.mainModel.cashierId = dataResponse.cashierId;
        this.billDetailTitle = `Phiếu trả hàng: #${this.mainModel.billNumber} - Ngày: ${dataResponse.createdAt}`;
        this.editable = dataResponse.companyId === 3 ? true : false;
        this.inputPropCashier.disabled = !this.editable;

        this.fetchAccountant();
        this.fetchCashAcc();
      });
    },
    roundMoney(value) {
      const tmpValue = Math.round(value / 1000);
      return tmpValue * 1000;
    },
    showApprovalModal(paymentType) {
      const data = {
        amount:
          paymentType === APPROVAL_PAYMENT_TYPE.CASH
            ? unMaskPrice(this.mainModel.paymentInfo.cashAmount)
            : unMaskPrice(this.mainModel.paymentInfo.transferAmount),
        customerId: this.mainModel.customerId,
        customerName: this.mainModel.customerName,
        customerMobile: this.mainModel.customerPhone,
        storeId: this.mainModel.storeId,
        paymentType: paymentType,
      };
      this.$refs['popup-approval'].showModal(
        this.mainModel.billNumber,
        data,
        APPROVAL_TYPE_ENUM.REFUND,
      );
    },
    splitTotalDiscountAmount(bill, details) {
      if (!details || !details.length) return [];

      const sumTotalAmount =
        Number(_.sumBy(bill.listBillItems, 'totalPrice')) || 0;
      const pointToMoney = _.toNumber(bill.totalAmountPoint) || 0;
      const discountValue = _.toNumber(bill.discountValue) || 0;

      const discountAmountBill = pointToMoney + discountValue;
      const convetedDetails = [];
      for (const product of details) {
        if (product.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS) {
          convetedDetails.push({
            ...product,
            allotmentDiscountAmount: 0,
          });
        } else {
          const totalAmount = product.totalPrice;

          const rateCost =
            totalAmount / sumTotalAmount > 0 ? totalAmount / sumTotalAmount : 0;
          const allotmentDiscountAmount = rateCost * discountAmountBill;

          convetedDetails.push({
            ...product,
            allotmentDiscountAmount: _.round(allotmentDiscountAmount, -3),
          });
        }
      }

      // const totalAllotmentDiscount =
      //   Number(_.sumBy(convetedDetails, 'allotmentDiscountAmount')) || 0;
      // const remainder = discountAmountBill - totalAllotmentDiscount;
      // if (remainder) {
      //   const highestTotalAmountId = this.findIdHasHighestTotalAmount(details);
      //   const indexHightestProduct = convetedDetails.findIndex(
      //     (product) => product.id === highestTotalAmountId,
      //   );

      //   convetedDetails[
      //     indexHightestProduct
      //   ].allotmentDiscountAmount += remainder;
      // }
      console.log({ convetedDetails });
      return convetedDetails;
    },
    // splitTotalDiscountAmount(bill, details) {
    //   if (!details || !details.length) return [];
    //   const productItems = details.filter(
    //     (detail) => detail.billItemType !== BILL_ITEM_TYPE.PRODUCT_BONUS,
    //   );
    //   const sumTotalAmount = Number(_.sumBy(productItems, 'totalPrice')) || 0;
    //   const pointToMoney = _.toNumber(bill.totalAmountPoint) || 0;
    //   const discountValue = _.toNumber(bill.discountValue) || 0;

    //   const discountAmountBill = pointToMoney + discountValue;
    //   const convetedDetails = [];
    //   for (const product of details) {
    //     if (product.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS) {
    //       convetedDetails.push({
    //         ...product,
    //         allotmentDiscountAmount: 0,
    //       });
    //     } else {
    //       const totalAmount = product.totalPrice;

    //       const rateCost = totalAmount / sumTotalAmount;
    //       // const rateCost = totalAmount / sumTotalAmount;
    //       const allotmentDiscountAmount = rateCost * discountAmountBill;

    //       convetedDetails.push({
    //         ...product,
    //         allotmentDiscountAmount: _.round(allotmentDiscountAmount, -3),
    //       });
    //     }
    //   }

    //   const totalAllotmentDiscount =
    //     Number(_.sumBy(convetedDetails, 'allotmentDiscountAmount')) || 0;
    //   const remainder = discountAmountBill - totalAllotmentDiscount;
    //   if (remainder) {
    //     const highestTotalAmountId = this.findIdHasHighestTotalAmount(details);
    //     const indexHightestProduct = convetedDetails.findIndex(
    //       (product) => product.id === highestTotalAmountId,
    //     );

    //     convetedDetails[
    //       indexHightestProduct
    //     ].allotmentDiscountAmount += remainder;
    //   }
    //   return convetedDetails;
    // },
    // splitTotalDiscountAmount(bill, details) {
    //   if (!details || !details.length) return [];
    //   const productItems = details.filter(
    //     (detail) => detail.billItemType !== BILL_ITEM_TYPE.PRODUCT_BONUS,
    //   );

    //   const sumTotalPrice = Number(_.sumBy(productItems, 'totalPrice')) || 0;
    //   const sumTotalAmount = bill.totalSaleAmount || 0;
    //   const totalAllotmentDiscountAmount = sumTotalPrice - bill.totalAmount;

    //   const pointToMoney = _.toNumber(bill.totalAmountPoint) || 0;
    //   const discountValue = _.toNumber(bill.discountValue) || 0;

    //   const discountAmountBill = pointToMoney + discountValue;
    //   const discountAmountNon =
    //     discountAmountBill - totalAllotmentDiscountAmount;
    //     console.log(discountAmountNon);
    //   const convetedDetails = [];
    //   for (const product of details) {
    //     if (product.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS) {
    //       convetedDetails.push({
    //         ...product,
    //         allotmentDiscountAmount: 0,
    //       });
    //     } else {
    //       const totalAmount = product.totalPrice;

    //       const rateCost = totalAmount / sumTotalAmount;
    //       const allotmentDiscountAmount = rateCost * discountAmountBill;

    //       convetedDetails.push({
    //         ...product,
    //         allotmentDiscountAmount: _.round(allotmentDiscountAmount, -3),
    //       });
    //     }
    //   }

    //   const totalAllotmentDiscount =
    //     Number(_.sumBy(convetedDetails, 'allotmentDiscountAmount')) || 0;
    //   const remainder =
    //     discountAmountBill - totalAllotmentDiscount - discountAmountNon;
    //   if (remainder) {
    //     const highestTotalAmountId = this.findIdHasHighestTotalAmount(details);
    //     const indexHightestProduct = convetedDetails.findIndex(
    //       (product) => product.id === highestTotalAmountId,
    //     );
    //     convetedDetails[
    //       indexHightestProduct
    //     ].allotmentDiscountAmount += remainder;
    //   }
    //   return convetedDetails;
    // },
    findIdHasHighestTotalAmount(details) {
      const INITIAL_INDEX = 0;
      if (details.length === 1) return details[INITIAL_INDEX].id;
      const _details = [...details];

      const sortedDetails = _details.sort(
        (prevItem, currentItem) => prevItem.totalPrice - currentItem.totalPrice,
      );
      const highestTotalAmountId = sortedDetails[sortedDetails.length - 1].id;

      return highestTotalAmountId;
    },
  },
  components: {
    KTCodePreview,
    ReturnBillItem,
    BillTransactions,
    ApprovalModal,
  },
  computed: {
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.quantity != 0) {
          if (element.billItemType === BILL_ITEM_TYPE.PRODUCT) {
            // total giá chiết khấu
            let totalPrice =
              unMaskPrice(element.price) * unMaskPrice(element.quantity);

            // Check discount type
            if (element.discountType == AMOUNT_TYPE.PERCENT) {
              let discountAmount = totalPrice * element.discount;
              totalPrice = totalPrice - (discountAmount / 100).toFixed(0);
            } else {
              totalPrice = totalPrice - unMaskPrice(element.discount);
            }
            if (element.customerDiscountType === 'percent') {
              let amount = totalPrice * (element.customerDiscountAmount / 100);
              if (
                element.customerDiscountMaxAmount &&
                amount > element.customerDiscountMaxAmount
              ) {
                amount = element.customerDiscountMaxAmount;
              }
              if (
                element.customerDiscountMaxAmount &&
                amount > element.customerDiscountMaxAmount
              ) {
                amount = element.customerDiscountMaxAmount;
              }
              totalPrice = totalPrice - amount.toFixed(0);
              totalPrice = Number(totalPrice) < 0 ? 0 : totalPrice;
              element.customerDiscountSellingPrice = totalPrice;
            }
            if (element.customerDiscountType === 'amount') {
              totalPrice =
                totalPrice - element.customerDiscountAmount < 0
                  ? 0
                  : totalPrice - element.customerDiscountAmount;
              element.customerDiscountSellingPrice = totalPrice;
            }
            total += totalPrice - element.allotmentDiscountAmount;
          } else if (
            element.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS &&
            element.checked === false
          ) {
            total -=
              unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);
          } else if (
            element.billItemType === BILL_ITEM_TYPE.PRODUCT_PROMTION &&
            element.checked === true
          ) {
            let totalPrice =
              unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);

            if (element.customerDiscountType === 'percent') {
              let amount = totalPrice * (element.customerDiscountAmount / 100);
              if (
                element.customerDiscountMaxAmount &&
                amount > element.customerDiscountMaxAmount
              ) {
                amount = element.customerDiscountMaxAmount;
              }
              totalPrice = totalPrice - amount.toFixed(0);
              totalPrice = Number(totalPrice) < 0 ? 0 : totalPrice;
              element.customerDiscountSellingPrice = totalPrice;
            }
            if (element.customerDiscountType === 'amount') {
              totalPrice =
                totalPrice - element.customerDiscountAmount < 0
                  ? 0
                  : totalPrice - element.customerDiscountAmount;
              element.customerDiscountSellingPrice = totalPrice;
            }
            total += totalPrice - element.allotmentDiscountAmount;
          }
        }
      }
      const discountAmount = this.mainModel.discountAmount
        ? unMaskPrice(this.mainModel.discountAmount)
        : 0;
      if (this.mainModel.discountType == AMOUNT_TYPE.MONEY) {
        total = total - discountAmount;
      } else {
        total = total - (total * discountAmount) / 100;
      }
      return total || 0;
    },
    //   totalPrice() {
    //     let total = 0;
    //     for (let index = 0; index < this.listBillItem.length; index++) {
    //       const element = this.listBillItem[index];
    //       if (element.billItemType === 1) {
    //         const totalPrice = unMaskPrice(element.price) * element.quantity;
    //         // Check discount type
    //         if (parseInt(element.discountType) === AMOUNT_TYPE.PERCENT) {
    //           let discountAmount = totalPrice * unMaskPrice(element.discount);
    //           total +=
    //             totalPrice -
    //             (discountAmount / 100).toFixed(0) -
    //             element.allotmentDiscountAmount;
    //         } else {
    //           total +=
    //             totalPrice -
    //             unMaskPrice(element.discount) -
    //             element.allotmentDiscountAmount;
    //         }
    //       } else if (element.billItemType === 2 && element.checked === false) {
    //         total -= unMaskPrice(element.returnPrice) * element.quantity;
    //       } else if (element.billItemType === 3 && element.checked === true) {
    //         total +=
    //           unMaskPrice(element.returnPrice) * element.quantity -
    //           element.allotmentDiscountAmount;
    //       }
    //     }
    //     return total;
    //   },
  },
  created() {
    this.getListEmployee();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu trả hàng', route: '/returnBills' },
      { title: 'Danh sách phiếu trả hàng', route: '/returnBills' },
      { title: 'Cập nhật phiếu trả hàng' },
    ]);
    this.mainModel.billNumber = this.$route.query.id;
    this.getBillById();
  },
};
</script>

<style>
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
  z-index: 10000001;
  font-weight: 400;
  background: white;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
